<script setup>
import {reactive} from "vue";

const state = reactive({
    isFooterExpanded: false
})
</script>

<template>
    <footer>
        <div class="footerwrapper">
            <div class="top">
                <div class="logo">
                    <img src="/images/spinza-logo.svg" alt="Spinza Logo" />
                </div>
                <div class="links">
                    <nav>
                        <h4>Casino</h4>
                        <router-link :to="{name: 'webplay.live-casino'}">Live Casino</router-link>
                        <router-link :to="{name: 'promotions'}">Promotions</router-link>
                    </nav>
                    <nav>
                        <h4>Company</h4>
                        <router-link :to="{name: 'how-to'}">How To</router-link>
                        <router-link :to="{name: 'about-us'}">About Us</router-link>
                        <a href="mailto:support@spinza.co.za">Contact Us</a>
                    </nav>
                    <nav>
                        <h4>Resources</h4>
                        <a href="/terms-and-conditions">Terms</a>
                        <a href="/privacy-policy">Privacy Policy</a>
                        <a href="/responsible-gambling">Responsible Gaming</a>
                    </nav>
                </div>
            </div>
            <div class="middle">
                <div class="content" :class="{ 'show': state.isFooterExpanded }" :style="{ 'height': state.isFooterExpanded ? '281px' : '150px' }">
                    <p><router-link class="homeRouterLink" :to="{ name: 'home' }">Spinza.co.za</router-link> features all of the thrills of the greatest land-based casino. The best games selections in casino gaming from the comfort of your own device, seven days a week. We have all of the games you'd expect from a first-rate casino in South Africa, as well as easy banking, superb customer service, and fantastic promotions.</p>
                    <p>There's little chance you'll ever get bored with 350 different slots titles spanning both regular and progressive jackpots. Fresh titles are introduced on a regular basis, so you'll never be bored.</p>
                    <p><router-link class="homeRouterLink" :to="{ name: 'home' }">Spinza.co.za</router-link>provides slots, video poker, standard or progressive video keno games, and a variety of scratch cards with massive jackpots.
                        Table game fans may choose from a number of blackjack, roulette, casino poker, and baccarat versions. Credit cards, bank wires, cryptocurrency, money orders, and person-to-person transfers are all options for deposit transactions. We reward players with a variety of promotions in addition to our extensive banking choices.</p>
                    <button id="btnJoinNowFooter" @click="authStore.setAuthModalVisibility(true, 'registration')">JOIN NOW</button>
                </div>
                <span class="mask"></span>
                <button class="toggler" @click="state.isFooterExpanded = !state.isFooterExpanded">
                    <span></span><span></span><span></span>
                </button>
            </div>
            <div class="bottom">
                <div class="over18">+18</div>
                <div class="copyright">&copy; 2024 Spinza All rights reserved. Live Play Gaming International (Company). The Company is licensed and regulated by the Government of Curaçao under the gaming license 390233 Apale Graham J. Kalvarna K/W Seehuis Teeli VI, Curaçao, Netherlands Antilles.</div>
            </div>
        </div>
        <div class="banking">
            <img src="https://assets.spinza.co.za/banking/dep-zar-white.svg" class="depositmethods" alt="Deposit methods"/>
        </div>
    </footer>
</template>

<style scoped>
    .homeRouterLink {
        color: #007a4d;
        text-decoration: none;
    }
</style>
