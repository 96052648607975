import axios from "axios"
import {defineStore} from "pinia"
import {ref} from "vue"

import {useApi} from "@shared/composables/useApi"
import {useLoaderStore} from "@shared/stores/LoaderStore"
import {useNotificationsStore} from "@shared/stores/NotificationsStore"

const COUPON_TYPE_DEPOSIT = "deposit"
const STORAGE_KEY_SIGN_UP_COUPON = "play_live_sign_up_coupon"

export const useCouponStore = defineStore('CouponStore', () => {

    const {apiPost} = useApi()

    const notificationsStore = useNotificationsStore()
    const loaderStore = useLoaderStore()

    const isCouponModalVisible = ref(false)
    const currentlyActiveCoupon = ref(null)
    const couponCode = ref(null)
    const isWageringActive = ref(false)

    const setCouponModalVisibility = async (visibility) => {
        if (visibility) {
            await fetchActiveCoupon()
        }
        isCouponModalVisible.value = visibility
    }

    const readUrlCoupon = () => {
        try {
            let signUpCoupon = localStorage.getItem(STORAGE_KEY_SIGN_UP_COUPON) || new URL(window.location.href).searchParams.get('coupon')

            if (signUpCoupon) {
                signUpCoupon = typeof signUpCoupon === 'string' ? JSON.parse(signUpCoupon) : {
                    code: signUpCoupon,
                    used: false
                }
                localStorage.setItem(STORAGE_KEY_SIGN_UP_COUPON, JSON.stringify(signUpCoupon))
            }
        } catch (e) {
            localStorage.removeItem(STORAGE_KEY_SIGN_UP_COUPON)
        }
    }

    const fetchActiveCoupon = async () => {
        loaderStore.increment()
        const {data: {activeCoupon, activeWagering}} = await axios.get('/api/getActiveCoupon')
        isWageringActive.value = activeWagering
        if(activeWagering !== true) {
            activeCoupon && (currentlyActiveCoupon.value = activeCoupon)
        } else {
            currentlyActiveCoupon.value = null
        }
        loaderStore.decrement()
    }

    const redeemCoupon = async (couponCode) => {
        try {
            loaderStore.increment()
            const {data} = await axios.post(
                '/api/couponRedeem', {
                    code: couponCode
                }
            )
            currentlyActiveCoupon.value = data.activeCoupon
            return true
        } catch ({ response: { data } }) {
            throw new Error(data.error)
        } finally {
            loaderStore.decrement()
        }
    }

    const setCouponCode = (code) => {
        couponCode.value = code
    }

    const cancelCoupon = async() => {
        try {
            const response = await apiPost('api/couponOptOut')
            currentlyActiveCoupon.value = null
            await fetchActiveCoupon()
            setCouponModalVisibility(false)

            if(response.data.success) {
                notificationsStore.showAlert('You successfully opted out of the bonus.')
            } else {
                notificationsStore.showAlert('An error occurred while opting out of the bonus.')
            }
        } catch (e) {
            console.error(e)
        }
    }

    return {
        activeCoupon: currentlyActiveCoupon,
        cancelCoupon,
        isCouponModalVisible,
        isWageringActive,
        couponCode,
        fetchActiveCoupon,
        redeemCoupon,
        setCouponModalVisibility,
        setCouponCode
    }
})
